<template>
    <div class="card">
        <TabView v-model:activeIndex="activeIndex" :lazy="true">
            <TabPanel header="箱子标签">
                <div>
                    下载箱子标签：<InputText type="text" placeholder="请输入FBA编号" v-model="downLoadShipmentId" />
                    <Button class="ml-2" type="button" icon="pi pi-download" label="下载" @click="reGetLabel()" />
                </div>
            </TabPanel>
            <TabPanel header="装箱单表格">
                <span class="ml-3">已选择 SKU数量：{{postItems.length}}</span><span class="ml-3">商品数量：{{totalItem}}</span><span class="ml-3">箱数：{{boxs.length}}</span>
                <DataTable :value="boxList" :paginator="boxList.length>10?true:false" class="p-datatable-sm mt-3" v-model:expandedRows="expandedItems" dataKey="boxId" stripedRows  :rows="10">
            <Column :expander="true" style="max-width:3rem" />
            <Column field="boxName" header="箱子名称">
                <template #body="{data}">
                    {{ data.boxName }}
                    <Tag v-if="data.error" class="ml-2" icon="pi pi-times" severity="danger" value="此箱子有商品条码不属于本店铺"></Tag>
                </template>
            </Column>
            <Column field="boxCount" header="箱子数量"></Column>
            <Column field="productCount" header="商品总数"></Column>
            <Column style="text-align:right;">
                <template #body="{data}">
                    选择箱数：<InputNumber v-model="data.qty" inputStyle="width:60px;" :allowEmpty="false" mode="decimal" :min="0" :max="data.boxCount" :disabled="data.isConfirm"/>
                    <Button type="button" :label="data.btnLabel" class="ml-2" @click="confirmclick(data.boxId)" />
                </template>
            </Column>
            <template #expansion="{data}">
                <div class="card mb-0" style="width:100%">
                    <DataTable :value="data.products" class="p-datatable-sm mt-3" stripedRows  :rows="20">
                        <Column field="fnsku" header="商品信息">
                            <template #body="{data}">
                                <itemInfo  :item="{imgUrl:translate[data.fnsku].imgUrl,asin:'1',fnsku:data.fnsku,sku:'1'}">
                                    <template #asin>
                                        商品名称：{{translate[data.fnsku].productNameZh}}
                                    </template>
                                    <template #sku>
                                        ASIN：{{translate[data.fnsku].asin}}
                                    </template>
                                </itemInfo>
                            </template>
                        </Column>
                        <Column field="qty" header="数量"></Column>
                    </DataTable> 
                </div>
            </template>   
            <template #footer>
                <div style="justify-content:space-between;display: flex;">
                    <div style="display: flex;">
                    <FileUpload name="excel[]" mode="basic" @select="(event)=>{files=event.files;}" @clear="()=>{files=null;}" chooseIcon="pi pi-times" :customUpload="true"  chooseLabel="选择文件" :showCancelButton="false"/>
                    <Button :disabled="btnNextDisable" class="ml-3" label="生成表格" @click="createClick" />
                    </div>
                    <Button icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="()=>{this.getBoxList();}" />
                </div>
            </template>
        </DataTable>
            </TabPanel>
        </TabView>
    </div>

</template>

<script>
import Service from '../../service/Service';
import ExcelJS from 'exceljs';
import itemInfo from '../common/itemInfo.vue';

export default {
    data() {
        return {
            files:null,
            downLoadShipmentId:null,
            boxList:[],
            expandedItems:[],
            btnNextDisable:true,
            translate:{},
            totalItem:0,
            postItems:[],
            boxs:[],
            activeIndex:0,
        }
    },
    created() {
        this.service = new Service();
    },
    mounted() {
        
    },
    watch:{
        totalItem:function(){
            if(this.totalItem>0){
                this.btnNextDisable=false;
            }
            else{
                this.btnNextDisable=true;
            }
        },
        activeIndex:function(){
            if(this.activeIndex==1&&this.boxList.length==0){
                this.getBoxList();
            }
        }
    },
    methods: {
        reGetLabel(){
            if(!this.downLoadShipmentId){
                return;
            }
            window.open('https://image.mizongkeji.com/tempFile/'+this.downLoadShipmentId+'.pdf');
        },
        getBoxList(){
            this.boxList=[];
            var url='shipment/getBoxList.php';
            this.service.sendHttp(url).then(res => res.json()).then(data => {
                if(data.result=='success'){
                    data.boxList.forEach(box=>{
                        console.log(box);
                        var content=JSON.parse(box.content);
                        this.translate={...this.translate,...content.translate};
                        box.products=content.boxContent.products;
                        box.boxSizeWeight=content.boxContent.boxSizeWeight;
                        box.content='';
                        box.qty=0;
                        box.btnLabel='确认';
                        box.isConfirm=false;
                        box.productCount=box.products.map(item=>{return item.qty}).reduce((total,num)=>{return total+num});
                        box.productCount*=box.boxCount;
                    });
                    this.boxList=data.boxList;
                    this.calculateTotal();
                }
            });
            
        },
        confirmclick(boxId) {
            this.boxList.forEach(box=>{
                if(box.boxId==boxId){
                    if(box.isConfirm){
                        box.isConfirm=false;
                        box.btnLabel='确认';
                    }
                    else{
                        box.isConfirm=true;
                        box.btnLabel='编辑'
                    }
                }
            });
            this.calculateTotal();
        },
        calculateTotal(){
            this.totalItem=0;
            this.totalSku=0;
            this.postItems=[];
            this.boxs=[];
            this.selectedBoxs=[];
            var confirmBoxs=this.boxList.filter(box=>box.isConfirm==true);
            confirmBoxs.forEach(box=>{
                box.products.forEach(product=>{
                    var isExist=this.postItems.some(item=>item.fnsku==product.fnsku);
                    if(!isExist){
                        var item={productName:this.translate[product.fnsku].productName,asin:this.translate[product.fnsku].asin,sku:this.translate[product.fnsku].sku,fnsku:product.fnsku,qty:parseInt(product.qty)*parseInt(box.qty)};
                        this.postItems.push(item);
                    }
                    else{
                        this.postItems.forEach(item=>{
                            if(item.fnsku==product.fnsku){
                                item.qty+=parseInt(product.qty)*parseInt(box.qty);
                            }
                        });
                    }
                });
                for(var i=0;i<box.qty;i++){
                    var postBox={boxId:this.boxs.length+1,products:box.products,boxSizeWeight:box.boxSizeWeight};
                    this.boxs.push(postBox);
                }
            });
            this.totalItem=this.postItems.map(item=>{return item.qty}).reduce((total,num)=>{return total+num});
        },
        downloadExcel(workbook){
            // 生成新的 Excel 文件并下载
            workbook.xlsx.writeBuffer().then(updatedData => {
                    const blob = new Blob([updatedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = '装箱单.xlsx';
                    link.click();
                    URL.revokeObjectURL(url);
                });
        },
        createClick(){
            if(!this.files){
                alert('请先选择文件！');
            }
            const file = this.files[0];
            const workbook = new ExcelJS.Workbook();
            workbook.xlsx.load(file).then(() => {
                // 获取工作表
                const worksheet = workbook.getWorksheet('包装箱包装信息');
                var markRow=this.getRowIndex(worksheet,'包装箱名称','A');
                if(markRow<0){
                    alert('表格有误！');
                    return;
                }
                var errorFnsku='';
                this.boxs.forEach(box=>{
                    var colIndex=this.numberToColumn(12+box.boxId);
                    box.products.forEach(item=>{
                        var rowIndex=this.getRowIndex(worksheet,item.fnsku,'E',markRow-2);
                        if(rowIndex<0){
                            errorFnsku+=item.fnsku+' ';
                            return;
                        }
                        worksheet.getCell(colIndex+rowIndex).value=item.qty;
                    });
                    //输入重量
                    worksheet.getCell(colIndex+(markRow+1)).value=this.kgToLbs(box.boxSizeWeight.weight);
                    //输入尺寸
                    worksheet.getCell(colIndex+(markRow+2)).value=this.cmToInches(box.boxSizeWeight.width);
                    worksheet.getCell(colIndex+(markRow+3)).value=this.cmToInches(box.boxSizeWeight.length);
                    worksheet.getCell(colIndex+(markRow+4)).value=this.cmToInches(box.boxSizeWeight.height);
                });
                if(errorFnsku==''){
                    this.downloadExcel(workbook);
                }else{
                    alert('选择的商品'+errorFnsku+'与表格不符！');
                }
            });
        },
        getRowIndex(worksheet,text,col,row=100){
            for(var i=6;i<=row;i++){
                if(worksheet.getCell(col+i).value==text){
                    return i;
                }
            }
            return -1;
        },
        numberToColumn(number) {
            let dividend = number;
            let columnName = '';
            let modulo;
            while (dividend > 0) {
                modulo = (dividend - 1) % 26;
                columnName = String.fromCharCode(65 + modulo) + columnName;
                dividend = Math.floor((dividend - modulo) / 26);
            }
            return columnName;
        },
        kgToLbs(kg) {
            const lbsPerKg = 2.20462;
            return (kg * lbsPerKg).toFixed(1);
        },
        cmToInches(cm) {
            const inchesPerCm = 0.393701;
            return (cm * inchesPerCm).toFixed(1);
        }
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>

